var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { useNotification as useRcNotification } from 'rc-notification';
import classNames from 'classnames';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { ConfigContext } from '../config-provider';
import useStyle from './style';
import { getMotion, wrapPromiseFn } from './util';
import warning from '../_util/warning';
import { PureContent } from './PurePanel';
const DEFAULT_OFFSET = 8;
const DEFAULT_DURATION = 3;
const Holder = React.forwardRef((props, ref) => {
    const { top, prefixCls: staticPrefixCls, getContainer: staticGetContainer, maxCount, duration = DEFAULT_DURATION, rtl, transitionName, onAllRemoved, } = props;
    const { getPrefixCls, getPopupContainer } = React.useContext(ConfigContext);
    const prefixCls = staticPrefixCls || getPrefixCls('message');
    const [, hashId] = useStyle(prefixCls);
    // =============================== Style ===============================
    const getStyle = () => ({
        left: '50%',
        transform: 'translateX(-50%)',
        top: top !== null && top !== void 0 ? top : DEFAULT_OFFSET,
    });
    const getClassName = () => classNames(hashId, rtl ? `${prefixCls}-rtl` : '');
    // ============================== Motion ===============================
    const getNotificationMotion = () => getMotion(prefixCls, transitionName);
    // ============================ Close Icon =============================
    const mergedCloseIcon = (React.createElement("span", { className: `${prefixCls}-close-x` },
        React.createElement(CloseOutlined, { className: `${prefixCls}-close-icon` })));
    // ============================== Origin ===============================
    const [api, holder] = useRcNotification({
        prefixCls,
        style: getStyle,
        className: getClassName,
        motion: getNotificationMotion,
        closable: false,
        closeIcon: mergedCloseIcon,
        duration,
        getContainer: () => (staticGetContainer === null || staticGetContainer === void 0 ? void 0 : staticGetContainer()) || (getPopupContainer === null || getPopupContainer === void 0 ? void 0 : getPopupContainer()) || document.body,
        maxCount,
        onAllRemoved,
    });
    // ================================ Ref ================================
    React.useImperativeHandle(ref, () => (Object.assign(Object.assign({}, api), { prefixCls,
        hashId })));
    return holder;
});
// ==============================================================================
// ==                                   Hook                                   ==
// ==============================================================================
let keyIndex = 0;
export function useInternalMessage(notificationConfig) {
    const holderRef = React.useRef(null);
    // ================================ API ================================
    const wrapAPI = React.useMemo(() => {
        // Wrap with notification content
        // >>> close
        const close = (key) => {
            var _a;
            (_a = holderRef.current) === null || _a === void 0 ? void 0 : _a.close(key);
        };
        // >>> Open
        const open = (config) => {
            if (!holderRef.current) {
                warning(false, 'Message', 'You are calling notice in render which will break in React 18 concurrent mode. Please trigger in effect instead.');
                const fakeResult = () => { };
                fakeResult.then = () => { };
                return fakeResult;
            }
            const { open: originOpen, prefixCls, hashId } = holderRef.current;
            const noticePrefixCls = `${prefixCls}-notice`;
            const { content, icon, type, key, className, onClose } = config, restConfig = __rest(config, ["content", "icon", "type", "key", "className", "onClose"]);
            let mergedKey = key;
            if (mergedKey === undefined || mergedKey === null) {
                keyIndex += 1;
                mergedKey = `antd-message-${keyIndex}`;
            }
            return wrapPromiseFn((resolve) => {
                originOpen(Object.assign(Object.assign({}, restConfig), { key: mergedKey, content: (React.createElement(PureContent, { prefixCls: prefixCls, type: type, icon: icon }, content)), placement: 'top', className: classNames(type && `${noticePrefixCls}-${type}`, hashId, className), onClose: () => {
                        onClose === null || onClose === void 0 ? void 0 : onClose();
                        resolve();
                    } }));
                // Return close function
                return () => {
                    close(mergedKey);
                };
            });
        };
        // >>> destroy
        const destroy = (key) => {
            var _a;
            if (key !== undefined) {
                close(key);
            }
            else {
                (_a = holderRef.current) === null || _a === void 0 ? void 0 : _a.destroy();
            }
        };
        const clone = {
            open,
            destroy,
        };
        const keys = ['info', 'success', 'warning', 'error', 'loading'];
        keys.forEach((type) => {
            const typeOpen = (jointContent, duration, onClose) => {
                let config;
                if (jointContent && typeof jointContent === 'object' && 'content' in jointContent) {
                    config = jointContent;
                }
                else {
                    config = {
                        content: jointContent,
                    };
                }
                // Params
                let mergedDuration;
                let mergedOnClose;
                if (typeof duration === 'function') {
                    mergedOnClose = duration;
                }
                else {
                    mergedDuration = duration;
                    mergedOnClose = onClose;
                }
                const mergedConfig = Object.assign(Object.assign({ onClose: mergedOnClose, duration: mergedDuration }, config), { type });
                return open(mergedConfig);
            };
            clone[type] = typeOpen;
        });
        return clone;
    }, []);
    // ============================== Return ===============================
    return [
        wrapAPI,
        React.createElement(Holder, Object.assign({ key: "message-holder" }, notificationConfig, { ref: holderRef })),
    ];
}
export default function useMessage(notificationConfig) {
    return useInternalMessage(notificationConfig);
}
