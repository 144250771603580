import { useEffect, useRef } from 'react';
import useForceUpdate from '../../_util/hooks/useForceUpdate';
import useResponsiveObserver from '../../_util/responsiveObserver';
function useBreakpoint(refreshOnChange = true) {
    const screensRef = useRef({});
    const forceUpdate = useForceUpdate();
    const responsiveObserver = useResponsiveObserver();
    useEffect(() => {
        const token = responsiveObserver.subscribe((supportScreens) => {
            screensRef.current = supportScreens;
            if (refreshOnChange) {
                forceUpdate();
            }
        });
        return () => responsiveObserver.unsubscribe(token);
    }, []);
    return screensRef.current;
}
export default useBreakpoint;
