var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import CSSMotion from 'rc-motion';
import * as React from 'react';
import { useMemo, useRef } from 'react';
import { ConfigContext } from '../config-provider';
import { cloneElement } from '../_util/reactNode';
import Ribbon from './Ribbon';
import ScrollNumber from './ScrollNumber';
import useStyle from './style';
import { isPresetColor } from '../_util/colors';
const Badge = (_a) => {
    var { prefixCls: customizePrefixCls, scrollNumberPrefixCls: customizeScrollNumberPrefixCls, children, status, text, color, count = null, overflowCount = 99, dot = false, size = 'default', title, offset, style, className, showZero = false } = _a, restProps = __rest(_a, ["prefixCls", "scrollNumberPrefixCls", "children", "status", "text", "color", "count", "overflowCount", "dot", "size", "title", "offset", "style", "className", "showZero"]);
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('badge', customizePrefixCls);
    // Style
    const [wrapSSR, hashId] = useStyle(prefixCls);
    // ================================ Misc ================================
    const numberedDisplayCount = (count > overflowCount ? `${overflowCount}+` : count);
    const isZero = numberedDisplayCount === '0' || numberedDisplayCount === 0;
    const ignoreCount = count === null || (isZero && !showZero);
    const hasStatus = ((status !== null && status !== undefined) || (color !== null && color !== undefined)) &&
        ignoreCount;
    const showAsDot = dot && !isZero;
    const mergedCount = showAsDot ? '' : numberedDisplayCount;
    const isHidden = useMemo(() => {
        const isEmpty = mergedCount === null || mergedCount === undefined || mergedCount === '';
        return (isEmpty || (isZero && !showZero)) && !showAsDot;
    }, [mergedCount, isZero, showZero, showAsDot]);
    // Count should be cache in case hidden change it
    const countRef = useRef(count);
    if (!isHidden) {
        countRef.current = count;
    }
    const livingCount = countRef.current;
    // We need cache count since remove motion should not change count display
    const displayCountRef = useRef(mergedCount);
    if (!isHidden) {
        displayCountRef.current = mergedCount;
    }
    const displayCount = displayCountRef.current;
    // We will cache the dot status to avoid shaking on leaved motion
    const isDotRef = useRef(showAsDot);
    if (!isHidden) {
        isDotRef.current = showAsDot;
    }
    // =============================== Styles ===============================
    const mergedStyle = useMemo(() => {
        if (!offset) {
            return Object.assign({}, style);
        }
        const offsetStyle = { marginTop: offset[1] };
        if (direction === 'rtl') {
            offsetStyle.left = parseInt(offset[0], 10);
        }
        else {
            offsetStyle.right = -parseInt(offset[0], 10);
        }
        return Object.assign(Object.assign({}, offsetStyle), style);
    }, [direction, offset, style]);
    // =============================== Render ===============================
    // >>> Title
    const titleNode = title !== null && title !== void 0 ? title : (typeof livingCount === 'string' || typeof livingCount === 'number' ? livingCount : undefined);
    // >>> Status Text
    const statusTextNode = isHidden || !text ? null : React.createElement("span", { className: `${prefixCls}-status-text` }, text);
    // >>> Display Component
    const displayNode = !livingCount || typeof livingCount !== 'object'
        ? undefined
        : cloneElement(livingCount, (oriProps) => ({
            style: Object.assign(Object.assign({}, mergedStyle), oriProps.style),
        }));
    // InternalColor
    const isInternalColor = isPresetColor(color, false);
    // Shared styles
    const statusCls = classNames({
        [`${prefixCls}-status-dot`]: hasStatus,
        [`${prefixCls}-status-${status}`]: !!status,
        [`${prefixCls}-status-${color}`]: isInternalColor,
    });
    const statusStyle = {};
    if (color && !isInternalColor) {
        statusStyle.color = color;
        statusStyle.background = color;
    }
    const badgeClassName = classNames(prefixCls, {
        [`${prefixCls}-status`]: hasStatus,
        [`${prefixCls}-not-a-wrapper`]: !children,
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className);
    // <Badge status="success" />
    if (!children && hasStatus) {
        const statusTextColor = mergedStyle.color;
        return wrapSSR(React.createElement("span", Object.assign({}, restProps, { className: classNames(badgeClassName, hashId), style: mergedStyle }),
            React.createElement("span", { className: statusCls, style: statusStyle }),
            text && (React.createElement("span", { style: { color: statusTextColor }, className: `${prefixCls}-status-text` }, text))));
    }
    // <Badge status="success" count={<Icon type="xxx" />}></Badge>
    return wrapSSR(React.createElement("span", Object.assign({}, restProps, { className: classNames(badgeClassName, hashId) }),
        children,
        React.createElement(CSSMotion, { visible: !isHidden, motionName: `${prefixCls}-zoom`, motionAppear: false, motionDeadline: 1000 }, ({ className: motionClassName, ref }) => {
            const scrollNumberPrefixCls = getPrefixCls('scroll-number', customizeScrollNumberPrefixCls);
            const isDot = isDotRef.current;
            const scrollNumberCls = classNames({
                [`${prefixCls}-dot`]: isDot,
                [`${prefixCls}-count`]: !isDot,
                [`${prefixCls}-count-sm`]: size === 'small',
                [`${prefixCls}-multiple-words`]: !isDot && displayCount && displayCount.toString().length > 1,
                [`${prefixCls}-status-${status}`]: !!status,
                [`${prefixCls}-status-${color}`]: isInternalColor,
            });
            let scrollNumberStyle = Object.assign({}, mergedStyle);
            if (color && !isInternalColor) {
                scrollNumberStyle = scrollNumberStyle || {};
                scrollNumberStyle.background = color;
            }
            return (React.createElement(ScrollNumber, { prefixCls: scrollNumberPrefixCls, show: !isHidden, motionClassName: motionClassName, className: scrollNumberCls, count: displayCount, title: titleNode, style: scrollNumberStyle, key: "scrollNumber", ref: ref }, displayNode));
        }),
        statusTextNode));
};
Badge.Ribbon = Ribbon;
if (process.env.NODE_ENV !== 'production') {
    Badge.displayName = 'Badge';
}
export default Badge;
