import * as React from 'react';
import warning from '../../../_util/warning';
import { getColumnKey, getColumnPos, renderColumnTitle } from '../../util';
import FilterDropdown from './FilterDropdown';
function collectFilterStates(columns, init, pos) {
    let filterStates = [];
    (columns || []).forEach((column, index) => {
        var _a;
        const columnPos = getColumnPos(index, pos);
        if (column.filters || 'filterDropdown' in column || 'onFilter' in column) {
            if ('filteredValue' in column) {
                // Controlled
                let filteredValues = column.filteredValue;
                if (!('filterDropdown' in column)) {
                    filteredValues = (_a = filteredValues === null || filteredValues === void 0 ? void 0 : filteredValues.map(String)) !== null && _a !== void 0 ? _a : filteredValues;
                }
                filterStates.push({
                    column,
                    key: getColumnKey(column, columnPos),
                    filteredKeys: filteredValues,
                    forceFiltered: column.filtered,
                });
            }
            else {
                // Uncontrolled
                filterStates.push({
                    column,
                    key: getColumnKey(column, columnPos),
                    filteredKeys: (init && column.defaultFilteredValue
                        ? column.defaultFilteredValue
                        : undefined),
                    forceFiltered: column.filtered,
                });
            }
        }
        if ('children' in column) {
            filterStates = [...filterStates, ...collectFilterStates(column.children, init, columnPos)];
        }
    });
    return filterStates;
}
function injectFilter(prefixCls, dropdownPrefixCls, columns, filterStates, locale, triggerFilter, getPopupContainer, pos) {
    return columns.map((column, index) => {
        const columnPos = getColumnPos(index, pos);
        const { filterMultiple = true, filterMode, filterSearch } = column;
        let newColumn = column;
        if (newColumn.filters || newColumn.filterDropdown) {
            const columnKey = getColumnKey(newColumn, columnPos);
            const filterState = filterStates.find(({ key }) => columnKey === key);
            newColumn = Object.assign(Object.assign({}, newColumn), { title: (renderProps) => (React.createElement(FilterDropdown, { tablePrefixCls: prefixCls, prefixCls: `${prefixCls}-filter`, dropdownPrefixCls: dropdownPrefixCls, column: newColumn, columnKey: columnKey, filterState: filterState, filterMultiple: filterMultiple, filterMode: filterMode, filterSearch: filterSearch, triggerFilter: triggerFilter, locale: locale, getPopupContainer: getPopupContainer }, renderColumnTitle(column.title, renderProps))) });
        }
        if ('children' in newColumn) {
            newColumn = Object.assign(Object.assign({}, newColumn), { children: injectFilter(prefixCls, dropdownPrefixCls, newColumn.children, filterStates, locale, triggerFilter, getPopupContainer, columnPos) });
        }
        return newColumn;
    });
}
export function flattenKeys(filters) {
    let keys = [];
    (filters || []).forEach(({ value, children }) => {
        keys.push(value);
        if (children) {
            keys = [...keys, ...flattenKeys(children)];
        }
    });
    return keys;
}
function generateFilterInfo(filterStates) {
    const currentFilters = {};
    filterStates.forEach(({ key, filteredKeys, column }) => {
        const { filters, filterDropdown } = column;
        if (filterDropdown) {
            currentFilters[key] = filteredKeys || null;
        }
        else if (Array.isArray(filteredKeys)) {
            const keys = flattenKeys(filters);
            currentFilters[key] = keys.filter((originKey) => filteredKeys.includes(String(originKey)));
        }
        else {
            currentFilters[key] = null;
        }
    });
    return currentFilters;
}
export function getFilterData(data, filterStates) {
    return filterStates.reduce((currentData, filterState) => {
        const { column: { onFilter, filters }, filteredKeys, } = filterState;
        if (onFilter && filteredKeys && filteredKeys.length) {
            return currentData.filter((record) => filteredKeys.some((key) => {
                const keys = flattenKeys(filters);
                const keyIndex = keys.findIndex((k) => String(k) === String(key));
                const realKey = keyIndex !== -1 ? keys[keyIndex] : key;
                return onFilter(realKey, record);
            }));
        }
        return currentData;
    }, data);
}
function useFilter({ prefixCls, dropdownPrefixCls, mergedColumns, onFilterChange, getPopupContainer, locale: tableLocale, }) {
    const [filterStates, setFilterStates] = React.useState(() => collectFilterStates(mergedColumns, true));
    const mergedFilterStates = React.useMemo(() => {
        const collectedStates = collectFilterStates(mergedColumns, false);
        if (collectedStates.length === 0) {
            return collectedStates;
        }
        let filteredKeysIsAllNotControlled = true;
        let filteredKeysIsAllControlled = true;
        collectedStates.forEach(({ filteredKeys }) => {
            if (filteredKeys !== undefined) {
                filteredKeysIsAllNotControlled = false;
            }
            else {
                filteredKeysIsAllControlled = false;
            }
        });
        // Return if not controlled
        if (filteredKeysIsAllNotControlled) {
            // Filter column may have been removed
            const keyList = (mergedColumns || []).map((column, index) => getColumnKey(column, getColumnPos(index)));
            return filterStates
                .filter(({ key }) => keyList.includes(key))
                .map((item) => {
                const col = mergedColumns[keyList.findIndex((key) => key === item.key)];
                return Object.assign(Object.assign({}, item), { column: Object.assign(Object.assign({}, item.column), col), forceFiltered: col.filtered });
            });
        }
        warning(filteredKeysIsAllControlled, 'Table', 'Columns should all contain `filteredValue` or not contain `filteredValue`.');
        return collectedStates;
    }, [mergedColumns, filterStates]);
    const filters = React.useMemo(() => generateFilterInfo(mergedFilterStates), [mergedFilterStates]);
    const triggerFilter = (filterState) => {
        const newFilterStates = mergedFilterStates.filter(({ key }) => key !== filterState.key);
        newFilterStates.push(filterState);
        setFilterStates(newFilterStates);
        onFilterChange(generateFilterInfo(newFilterStates), newFilterStates);
    };
    const transformColumns = (innerColumns) => injectFilter(prefixCls, dropdownPrefixCls, innerColumns, mergedFilterStates, tableLocale, triggerFilter, getPopupContainer);
    return [transformColumns, mergedFilterStates, filters];
}
export default useFilter;
