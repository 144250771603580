import classNames from 'classnames';
import { composeRef, supportRef } from 'rc-util/lib/ref';
import isVisible from 'rc-util/lib/Dom/isVisible';
import React, { useContext, useRef } from 'react';
import { ConfigContext } from '../../config-provider';
import { cloneElement } from '../reactNode';
import useStyle from './style';
import useWave from './useWave';
const Wave = (props) => {
    const { children, disabled } = props;
    const { getPrefixCls } = useContext(ConfigContext);
    const containerRef = useRef(null);
    // ============================== Style ===============================
    const prefixCls = getPrefixCls('wave');
    const [, hashId] = useStyle(prefixCls);
    // =============================== Wave ===============================
    const showWave = useWave(containerRef, classNames(prefixCls, hashId));
    // ============================== Effect ==============================
    React.useEffect(() => {
        const node = containerRef.current;
        if (!node || node.nodeType !== 1 || disabled) {
            return;
        }
        // Click handler
        const onClick = (e) => {
            // Fix radio button click twice
            if (e.target.tagName === 'INPUT' ||
                !isVisible(e.target) ||
                // No need wave
                !node.getAttribute ||
                node.getAttribute('disabled') ||
                node.disabled ||
                node.className.includes('disabled') ||
                node.className.includes('-leave')) {
                return;
            }
            showWave();
        };
        // Bind events
        node.addEventListener('click', onClick, true);
        return () => {
            node.removeEventListener('click', onClick, true);
        };
    }, [disabled]);
    // ============================== Render ==============================
    if (!React.isValidElement(children)) {
        return (children !== null && children !== void 0 ? children : null);
    }
    const ref = supportRef(children) ? composeRef(children.ref, containerRef) : containerRef;
    return cloneElement(children, { ref });
};
if (process.env.NODE_ENV !== 'production') {
    Wave.displayName = 'Wave';
}
export default Wave;
