import classNames from 'classnames';
import ResizeObserver from 'rc-resize-observer';
import omit from 'rc-util/lib/omit';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import throttleByAnimationFrame from '../_util/throttleByAnimationFrame';
import useStyle from './style';
import { addObserveTarget, getFixedBottom, getFixedTop, getTargetRect, removeObserveTarget, } from './utils';
function getDefaultTarget() {
    return typeof window !== 'undefined' ? window : null;
}
var AffixStatus;
(function (AffixStatus) {
    AffixStatus[AffixStatus["None"] = 0] = "None";
    AffixStatus[AffixStatus["Prepare"] = 1] = "Prepare";
})(AffixStatus || (AffixStatus = {}));
class Affix extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            status: AffixStatus.None,
            lastAffix: false,
            prevTarget: null,
        };
        this.getOffsetTop = () => {
            const { offsetBottom, offsetTop } = this.props;
            return offsetBottom === undefined && offsetTop === undefined ? 0 : offsetTop;
        };
        this.getOffsetBottom = () => this.props.offsetBottom;
        this.savePlaceholderNode = (node) => {
            this.placeholderNode = node;
        };
        this.saveFixedNode = (node) => {
            this.fixedNode = node;
        };
        // =================== Measure ===================
        this.measure = () => {
            const { status, lastAffix } = this.state;
            const { onChange } = this.props;
            const targetFunc = this.getTargetFunc();
            if (status !== AffixStatus.Prepare || !this.fixedNode || !this.placeholderNode || !targetFunc) {
                return;
            }
            const offsetTop = this.getOffsetTop();
            const offsetBottom = this.getOffsetBottom();
            const targetNode = targetFunc();
            if (!targetNode) {
                return;
            }
            const newState = {
                status: AffixStatus.None,
            };
            const targetRect = getTargetRect(targetNode);
            const placeholderReact = getTargetRect(this.placeholderNode);
            const fixedTop = getFixedTop(placeholderReact, targetRect, offsetTop);
            const fixedBottom = getFixedBottom(placeholderReact, targetRect, offsetBottom);
            if (placeholderReact.top === 0 &&
                placeholderReact.left === 0 &&
                placeholderReact.width === 0 &&
                placeholderReact.height === 0) {
                return;
            }
            if (fixedTop !== undefined) {
                newState.affixStyle = {
                    position: 'fixed',
                    top: fixedTop,
                    width: placeholderReact.width,
                    height: placeholderReact.height,
                };
                newState.placeholderStyle = {
                    width: placeholderReact.width,
                    height: placeholderReact.height,
                };
            }
            else if (fixedBottom !== undefined) {
                newState.affixStyle = {
                    position: 'fixed',
                    bottom: fixedBottom,
                    width: placeholderReact.width,
                    height: placeholderReact.height,
                };
                newState.placeholderStyle = {
                    width: placeholderReact.width,
                    height: placeholderReact.height,
                };
            }
            newState.lastAffix = !!newState.affixStyle;
            if (onChange && lastAffix !== newState.lastAffix) {
                onChange(newState.lastAffix);
            }
            this.setState(newState);
        };
        this.prepareMeasure = () => {
            // event param is used before. Keep compatible ts define here.
            this.setState({
                status: AffixStatus.Prepare,
                affixStyle: undefined,
                placeholderStyle: undefined,
            });
            // Test if `updatePosition` called
            if (process.env.NODE_ENV === 'test') {
                const { onTestUpdatePosition } = this.props;
                onTestUpdatePosition === null || onTestUpdatePosition === void 0 ? void 0 : onTestUpdatePosition();
            }
        };
        this.updatePosition = throttleByAnimationFrame(() => {
            this.prepareMeasure();
        });
        this.lazyUpdatePosition = throttleByAnimationFrame(() => {
            const targetFunc = this.getTargetFunc();
            const { affixStyle } = this.state;
            // Check position change before measure to make Safari smooth
            if (targetFunc && affixStyle) {
                const offsetTop = this.getOffsetTop();
                const offsetBottom = this.getOffsetBottom();
                const targetNode = targetFunc();
                if (targetNode && this.placeholderNode) {
                    const targetRect = getTargetRect(targetNode);
                    const placeholderReact = getTargetRect(this.placeholderNode);
                    const fixedTop = getFixedTop(placeholderReact, targetRect, offsetTop);
                    const fixedBottom = getFixedBottom(placeholderReact, targetRect, offsetBottom);
                    if ((fixedTop !== undefined && affixStyle.top === fixedTop) ||
                        (fixedBottom !== undefined && affixStyle.bottom === fixedBottom)) {
                        return;
                    }
                }
            }
            // Directly call prepare measure since it's already throttled.
            this.prepareMeasure();
        });
    }
    getTargetFunc() {
        const { getTargetContainer } = this.context;
        const { target } = this.props;
        if (target !== undefined) {
            return target;
        }
        return getTargetContainer !== null && getTargetContainer !== void 0 ? getTargetContainer : getDefaultTarget;
    }
    // Event handler
    componentDidMount() {
        const targetFunc = this.getTargetFunc();
        if (targetFunc) {
            // [Legacy] Wait for parent component ref has its value.
            // We should use target as directly element instead of function which makes element check hard.
            this.timeout = setTimeout(() => {
                addObserveTarget(targetFunc(), this);
                // Mock Event object.
                this.updatePosition();
            });
        }
    }
    componentDidUpdate(prevProps) {
        const { prevTarget } = this.state;
        const targetFunc = this.getTargetFunc();
        const newTarget = (targetFunc === null || targetFunc === void 0 ? void 0 : targetFunc()) || null;
        if (prevTarget !== newTarget) {
            removeObserveTarget(this);
            if (newTarget) {
                addObserveTarget(newTarget, this);
                // Mock Event object.
                this.updatePosition();
            }
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ prevTarget: newTarget });
        }
        if (prevProps.offsetTop !== this.props.offsetTop ||
            prevProps.offsetBottom !== this.props.offsetBottom) {
            this.updatePosition();
        }
        this.measure();
    }
    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        removeObserveTarget(this);
        this.updatePosition.cancel();
        // https://github.com/ant-design/ant-design/issues/22683
        this.lazyUpdatePosition.cancel();
    }
    // =================== Render ===================
    render() {
        const { affixStyle, placeholderStyle } = this.state;
        const { affixPrefixCls, rootClassName, children } = this.props;
        const className = classNames({
            [rootClassName]: !!affixStyle,
            [affixPrefixCls]: !!affixStyle,
        });
        let props = omit(this.props, [
            'prefixCls',
            'offsetTop',
            'offsetBottom',
            'target',
            'onChange',
            'affixPrefixCls',
            'rootClassName',
        ]);
        // Omit this since `onTestUpdatePosition` only works on test.
        if (process.env.NODE_ENV === 'test') {
            props = omit(props, ['onTestUpdatePosition']);
        }
        return (React.createElement(ResizeObserver, { onResize: this.updatePosition },
            React.createElement("div", Object.assign({}, props, { ref: this.savePlaceholderNode }),
                affixStyle && React.createElement("div", { style: placeholderStyle, "aria-hidden": "true" }),
                React.createElement("div", { className: className, ref: this.saveFixedNode, style: affixStyle },
                    React.createElement(ResizeObserver, { onResize: this.updatePosition }, children)))));
    }
}
Affix.contextType = ConfigContext;
const AffixFC = React.forwardRef((props, ref) => {
    const { prefixCls: customizePrefixCls } = props;
    const { getPrefixCls } = React.useContext(ConfigContext);
    const affixPrefixCls = getPrefixCls('affix', customizePrefixCls);
    const [wrapSSR, hashId] = useStyle(affixPrefixCls);
    const AffixProps = Object.assign(Object.assign({}, props), { affixPrefixCls, rootClassName: hashId });
    return wrapSSR(React.createElement(Affix, Object.assign({}, AffixProps, { ref: ref })));
});
if (process.env.NODE_ENV !== 'production') {
    AffixFC.displayName = 'Affix';
}
export default AffixFC;
