// form item name black list.  in form ,you can use form.id get the form item element.
// use object hasOwnProperty will get better performance if black list is longer.
const formItemNameBlackList = ['parentNode'];
// default form item id prefix.
const defaultItemNamePrefixCls = 'form_item';
export function toArray(candidate) {
    if (candidate === undefined || candidate === false)
        return [];
    return Array.isArray(candidate) ? candidate : [candidate];
}
export function getFieldId(namePath, formName) {
    if (!namePath.length) {
        return undefined;
    }
    const mergedId = namePath.join('_');
    if (formName) {
        return `${formName}_${mergedId}`;
    }
    const isIllegalName = formItemNameBlackList.includes(mergedId);
    return isIllegalName ? `${defaultItemNamePrefixCls}_${mergedId}` : mergedId;
}
